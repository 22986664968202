import { ButtonBase, DialogContent, styled as muiStyled } from '@mui/material'
import PhoneInput from 'react-phone-input-2'
import styled from 'styled-components'
import { DAY_CALENDAR_EACH_SLOT_TWO_ROW_HEIGHT_PX } from '../../../consumer/constants'

export const AvailabilityPageContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  font-family: Outfit;
`

export const PageHeadlineContainer = styled.div`
  margin-bottom: 32px;
  padding: 20px 0;
  box-shadow: 0 20px 20px -20px rgb(0 0 0/15%);
`

export const PageHeadline = styled.div`
  max-width: 1020px;
  margin: 0 auto;
  font-size: 22px;
  font-weight: 400;
  color: #000;
  padding: 0 20px;
`

export const PageHeadlineComponent = ({ heading }) => {
  return (
    <PageHeadlineContainer>
      <PageHeadline>{heading}</PageHeadline>
    </PageHeadlineContainer>
  )
}

export const ClinicManageBookingsContainer = styled.div`
  @media print {
    display: none;
  }
  width: calc(100% - 40px);
  max-width: 980px;
  border: 1px solid #cccccc;
  border-radius: 8px;
  margin: 0 auto 40px;
  box-shadow: 0 1px 5px rgb(0 74 116/15%);
  background: #fff;
`

export const ClinicTabButton = styled.button`
  background: ${(props) => (props?.selected ? '#bcbcbc' : '#FFF')};
  border: 1px solid #000;
  border-radius: 15px;
  padding: 4px 8px;
`

export const ClinicTabButtonsContainer = styled.div`
  display: flex;
  padding: 10px 20px;
  flex-wrap: wrap;
  gap: 20px;
  border-bottom: 1px solid #cccccc;
`

//////////////////

// day view calendar styles
// EventsDayViewCalendar : EDVC

export const EDVCParent = styled.div`
  padding: 16px 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  @media print {
    display: none;
  }
  @media (min-width: 768px) {
    padding: 20px 24px;
  }
`

export const EDVCEventsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 12px;
  width: 100%;
`

export const EDVCDateContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 16px;
`

export const EDVCContainer = styled.div`
  width: 100%;
  padding: 20px;
  background: #f1f3f4;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
`

export const EDVCSlotItemContainer = styled.div`
  width: 100%;
  height: ${(props) => props?.heightOfEachSlot || `${DAY_CALENDAR_EACH_SLOT_TWO_ROW_HEIGHT_PX}px`};
  display: flex;
`

export const EDVCSlotItemTime = styled.div`
  width: 30px;
  flex-shrink: 0;
  position: relative;
`

export const EDVCSlotStartTime = styled.div`
  position: absolute;
  right: 2px;
  // top: 13px;
  font-size: 10px;
  line-height: 10px;
`

export const EDVCSlotItem = styled.div`
  width: 100%;
  background: #fff;
  border-top: 1px solid rgb(209 213 215);
  height: ${(props) => `${props?.heightOfEachSlot || DAY_CALENDAR_EACH_SLOT_TWO_ROW_HEIGHT_PX}px`};
  display: flex;
  align-items: center;
  overflow: hidden;
  color: #000;
  font-weight: 500;
  padding: 0 10px;
`

export const EDVCSlotColoredItem = styled.div`
  position: absolute;
  top: ${(props) => `${20 + props?.fromTopInPx}px`};
  height: ${(props) => `${props?.heightInPx}px`};
  right: 20px;
  width: calc(100% - 70px);
  background: ${(props) => (props?.selected ? '#039bff' : '#f00')};
  ${(props) => props?.booked && 'background: #737373'};
  ${(props) => props?.emergencyEvent && 'background:#a2414b'};
  ${(props) => props?.completed && 'background: #0D6F3C'};
  ${(props) => props?.blocked && 'background: #000'};
  border: 1px solid #fff;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  color: #fff;
  font-weight: 500;
  cursor: pointer;
  padding: 2px 10px;
  border-radius: 5px;
`

export const EDVCBookedSlotDetailsContainer = styled.div`
  width: 350px;
`

export const EDVCButton = styled.button`
  color: ${(props) => (props?.invert ? '#FFF' : '#864ef5')};
  background: ${(props) => (props?.invert ? '#864ef5' : '#FFF')};
  font-weight: 500;
  border: 1px solid #864ef5;
  border-radius: 15px;
  font-size: 14px;
  padding: 2px 8px;
  display: flex;
  margin-top: 3px;
  justify-content: center;
  align-items: center;
  &:disabled {
    color: #fff;
    background: #929292;
    border: 1px solid #929292;
  }
`

export const EDVCNewBookingContainer = styled(DialogContent)`
  min-width: 400px;
  min-height: 400px;
  @media (max-width: 980px) {
    min-width: calc(100vw - 100px);
  }
`

export const EDVCBookNewSlotInput = styled.input`
  color: #000;
  border: 1px solid #b2b2b2;
  border-radius: 8px;
  padding: 5px 10px;
  width: 100%;
`

export const EDVCBookNewSlotSelect = styled.select`
  border: 1px solid #b2b2b2;
  border-radius: 8px;
  padding: 5px 10px;
  width: 100%;
  &:focus {
    outline: none;
  }
`

export const EDVCPresentTimeLine = styled.div`
  position: absolute;
  top: ${(props) => `${20 + props?.fromTopInPx}px`};
  right: 20px;
  width: calc(100% - 70px);
  background: #ea4335;
  height: 2px;
`

export const EDVCPresentTimeLineDot = styled.div`
  position: absolute;
  left: 0;
  top: -3px;
  height: 8px;
  width: 8px;
  background: #ea4335;
  border-radius: 50%;
`

export const EDVCExtraSlotItemForTime = styled(EDVCSlotItem)`
  background: #f1f3f4;
  position: relative;
  border-radius: 5px;
  border-top: unset;
`

export const EDVCSlotButton = styled.button`
  padding: 2px 8px;
  border-radius: 10px;
  height: fit-content;
  background: ${(props) => (props?.infoVariant ? '#3d84ff' : '#e6f3ff')};
  color: ${(props) => (props?.infoVariant ? '#fff' : '#523b55')};
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 900px) {
    ${(props) => props?.hideOnSmallWidth && `display: none;`};
  }
`

export const EDVCRectangleButton = styled.button`
  height: 38px;
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  padding: 0 5px;
  border-radius: 4px;
  border: 1px solid #b3b3b3;
  background: ${(props) => props?.action && '#d6cbfe'};
  color: #4d4d50;
  &:disabled {
    background: transparent;
  }
`

// search section parent

export const SearchSectionParent = styled.div`
  width: 600px;
  @media (max-width: 600px) {
    width: 100%;
  }
`

//// patient info styles

export const PIParent = styled.div`
  width: 600px;
  display: flex;
  justify-content: space-between;
  @media (max-width: 600px) {
    width: 100%;
    flex-direction: column;
    gap: 10px;
  }
`

export const PISection = styled.div`
  width: 45%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  @media (max-width: 600px) {
    width: 100%;
  }
`

export const PISectionItem = styled.div`
  width: 100%;
  display: flex;
  gap: 10px;
`

export const PISectionValidation = styled.div`
  width: 100%;
  font-size: 12px;
  color: red;
`

export const PISectionTitle = styled.div`
  width: 80px;
  flex-shrink: 0;
  font-weight: 500;
`

export const PISectionInput = styled.input`
  color: #000;
  border: 1px solid #b2b2b2;
  border-radius: 5px;
  padding: 5px 10px;
  width: 100%;
  &:focus {
    outline: none;
  }
  &:disabled {
    background: #d8dbe0;
  }
`

//// rectangular radio button

export const RRBContainer = styled.div`
  width: 100%;
  display: flex;
  border-radius: 5px;
  border: 1px solid #b2b2b2;
  overflow: hidden;
`

export const RRBItem = muiStyled(ButtonBase)(({ selected }) => ({
  padding: '3px 3px',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
  background: selected ? '#b7d7f9' : 'inherit',
  borderLeft: selected ? '1px solid #a8cff8' : '1px solid #fff',
  borderRight: selected ? '1px solid #a8cff8' : '1px solid #fff',
  '&:hover': {
    background: selected ? '#a8cff8' : 'inherit',
  },
}))

////// mobile input

export const EDVCPhoneInput = styled(PhoneInput)`
  width: 100%;
  max-width: 315px;
  .form-control {
    border: 1px solid #707070;
    border-radius: 5px;
    color: #242424;
    &:focus {
      outline: none;
      box-shadow: none;
    }
  }
`

//// cash denomation stuff:

export const CashDenominationInput = styled.input`
  border: 1px solid #b2b2b2;
  border-radius: 3px;
  padding: 3px;
  width: 40px;
  font-weight: 500;
  &:focus {
    outline: none;
  }
`

export const CashDenominationText = styled.div`
  width: 60px;
  text-align: right;
`

// prescription individual page

export const PrescriptionIndividualPageParent = styled.div`
  padding: 20px;
  @media print {
    padding: 0px;
    .hide-on-print {
      display: none !important;
    }
    @page {
      size: A4;
      orientation: portrait;
      /* Enable background graphics */
      -webkit-print-color-adjust: exact; /* For Chrome */
      print-color-adjust: exact; /* For other browsers */
    }
  }
`

// therapy requests stuff:

export const BookingRequestItemsParent = styled.div`
  position: relative;
  padding: 20px;
  width: 100%;
  height: 100%;
`

export const BookingRequestContainer = styled.div`
  padding: 10px;
  display: flex;
  margin: 10px 0px;
  background: #fff8f7;
  border-radius: 8px;
  gap: 5px;
  flex-direction: column;
`

export const FloatingHeaderContainerDayCal = styled.div`
  position: sticky;
  top: 0;
  z-index: 10;
  background: white;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
`

// highlighted blocks:
export const HighlightedBlockContainer = styled.div`
  position: absolute;
  width: calc(100%);
  right: 20px;
  top: ${(props) => `${20 + props?.fromTopInPx}px`};
  height: ${(props) => `${props?.heightInPx}px`};
  background: rgba(0, 128, 128, 0.05);
  border-radius: 2px;
  pointer-events: none;
`

export const HighlightedBlockStartMarker = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  font-size: 8px;
  color: #2d333a;
  width: calc(100% - 70px);
  display: flex;
  justify-content: center;
  text-align: center;
  background: rgba(0, 128, 128, 0.2);
`

export const HighlightedBlockEndMarker = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  font-size: 8px;
  color: #2d333a;
  width: calc(100% - 70px);
  display: flex;
  justify-content: center;
  text-align: center;
  background: rgba(0, 128, 128, 0.2);
`

export const EachSlotDurationInMinsSelectionBox = styled.div`
  padding: 2px 8px;
  cursor: pointer;
  font-size: 12px;
  ${(props) => props?.bl && 'border-left: 1px solid #cecece;'};
  ${(props) => props?.br && 'border-right: 1px solid #cecece;'};
  background: ${(props) => (props?.selected ? '#005FB8' : '$fff')};
  color: ${(props) => (props?.selected ? '#fff' : '$000')};
`
