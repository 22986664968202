import { getCPDataWithParams } from 'src/clinic/services'
import { isCommonResponseSuccessfulV2 } from 'src/mentor/helpers/utilityFunctions'

export async function getPatientVitalForBookedEvent(
  patientId,
  clinicId,
  bookedEventId,
  single = true,
) {
  const response = await getCPDataWithParams(`/api/secure/patient/vitals`, {
    patientId,
    clinicId,
    bookedEventId,
  })
  if (
    response?.status === 200 &&
    isCommonResponseSuccessfulV2(response) &&
    Array.isArray(response?.data?.data?.vital)
  ) {
    if (single) {
      return response?.data?.data?.vital?.[0]
    }
    return response?.data?.data?.vital
  }
  return null
}
