import { useCallback, useEffect, useRef, useState } from 'react'
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom'

export const useScreenSize = () => {
  const [screenSize, setScreenSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  })

  useEffect(() => {
    const handleResize = () => {
      setScreenSize({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }

    window.addEventListener('resize', handleResize)

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return screenSize
}

const useEventListener = (event, listener, useCapture) => {
  useEffect(() => {
    if (listener) {
      listener()
      window.addEventListener(event, listener, useCapture)

      return () => window.removeEventListener(event, listener, useCapture)
    }

    return () => {}
  }, [event, listener, useCapture])
}

export const useDocumentSize = () => {
  const [documentSize, setDocumentSize] = useState({
    documentWidth: document.documentElement.scrollWidth,
    documentHeight: document.documentElement.scrollHeight,
  })

  useEffect(() => {
    const handleResize = () => {
      setDocumentSize({
        documentWidth: document.documentElement.scrollWidth,
        documentHeight: document.documentElement.scrollHeight,
      })
    }

    window.addEventListener('resize', handleResize)

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return documentSize
}

export function useDocumentTitle(title, prevailOnUnmount = false) {
  const defaultTitle = useRef(document.title)

  useEffect(() => {
    document.title = title
  }, [title])

  useEffect(
    () => () => {
      if (!prevailOnUnmount) {
        document.title = defaultTitle.current
      }
    },
    [],
  )
}

export const useScrollTo = ({ offset = 0 }) => {
  const ref = useRef(null)
  const [shouldScrollTo, setShouldScrollTo] = useState(false)

  useEffect(() => {
    if (ref.current && shouldScrollTo) {
      const barHeight = `${137 + offset}px`
      ref.current.style.scrollMargin = barHeight
      ref.current?.scrollIntoView({ behavior: 'smooth' })
      setShouldScrollTo(false)
    }
  }, [shouldScrollTo, offset])

  return [ref, setShouldScrollTo]
}

export const useScrollToWithParentRef = ({ offset = 0, parentRef }) => {
  const ref = useRef(null)
  const [shouldScrollTo, setShouldScrollTo] = useState(false)

  useEffect(() => {
    const scrollElement = parentRef ? parentRef.current : window

    if (ref.current && shouldScrollTo) {
      const barHeight = `${137 + offset}px`
      ref.current.style.scrollMargin = barHeight
      scrollElement.scrollTo({
        top: ref.current.offsetTop,
        behavior: 'smooth',
      })
      setShouldScrollTo(false)
    }
  }, [shouldScrollTo, offset, parentRef])

  return [ref, setShouldScrollTo]
}

export const useNavigateWithParams = () => {
  const navigate = useNavigate()
  const [urlSearchParams, setUrlSearchParams] = useSearchParams()

  return (url, params) => {
    const searchParams = createSearchParams(params).toString()
    const urlsrprm = urlSearchParams.toString()

    navigate(url + '?' + urlsrprm)
  }
}

const useElementDimensions = (props) => {
  const ref = useRef(null)
  const [dimensions, setDimensions] = useState(null)
  let finalProps = props || { updateOnScroll: true }

  const refresh = useCallback(() => {
    const domRect = ref.current?.getBoundingClientRect()
    if (domRect) {
      setDimensions(domRect)
    } else {
      setDimensions(null)
    }
  }, [])

  useEventListener('resize', refresh)
  useEventListener('scroll', finalProps?.updateOnScroll ? refresh : () => {}, true)

  return { dimensions, ref, refresh }
}

export function useHasScrolled() {
  const [hasScrolled, setHasScrolled] = useState(false)

  useEffect(() => {
    const handleScroll = () => {
      setHasScrolled(window.scrollY > 0)
    }

    window.addEventListener('scroll', handleScroll)
    handleScroll() // Initialize state on mount

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return hasScrolled
}

export default useElementDimensions
